export const appPageTransition = {"name":"page-transition","mode":"out-in"}

export const appHead = {"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/comicshannsmononerdfontmono-regular-webfont.woff2"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"theme-color","name":"theme-color","content":"transparent"},{"hid":"og:image","property":"og:image","content":"https://smartlnks-assets.s3.ap-south-1.amazonaws.com/smartlnks-index.png"},{"hid":"twitter:title","name":"twitter:title","content":"The Smartest, Secure Smart Link Generator and App Opener"},{"hid":"twitter:description","name":"twitter:description","content":"The globe's best free, secure smart link generator, and URL shortener. Craft and target your links with the most innovative tool on the internet."},{"hid":"twitter:image","name":"twitter:image","content":"https://smartlnks-assets.s3.ap-south-1.amazonaws.com/smartlnks-index.png"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"property":"og:type","content":"website"}],"style":[{"children":"@font-face{font-family:\"ComicShannsMono\";font-weight:400;font-style:normal;font-display:swap;src:url('/fonts/comicshannsmononerdfontmono-regular-webfont.woff2') format('woff2');}.comic-shann-mono{font-family:\"ComicShannsMono\",system-ui;}"}],"script":[{"src":"https://cloudflareinsights.com/cdn-cgi/rum","defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"},"titleTemplate":"%s"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false